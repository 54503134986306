import { useMemo } from "react";
import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import merge from "deepmerge";
import isEqual from "lodash/isEqual";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";

let apolloClient: ApolloClient<NormalizedCacheObject>;

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export function getApolloClient(ctx?: any) {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: from([
      errorLink,
      retryLink,
      new HttpLink({
        uri: process.env.GRAPHQL_URL,
        credentials: "same-origin",
      }),
    ]),

    cache: new InMemoryCache({
      dataIdFromObject: (object) => {
        switch (object.__typename) {
          case "UserPublic":
            return `${object.__typename}${object.uuid}`;
          default:
            return `${object.__typename}${object.id}`;
        }
      },
      typePolicies: {
        NewsPublisher: {
          fields: {
            news: {
              // https://www.apollographql.com/docs/react/pagination/key-args/
              keyArgs: false,
              merge(existing = [], incoming: any[]) {
                return [...existing, ...incoming];
              },
            },
          },
        },
        News: {
          fields: {
            comments: {
              // https://www.apollographql.com/docs/react/pagination/key-args/
              keyArgs: false,
              merge(existing = [], incoming: any[]) {
                const uniqueIncoming = incoming.filter(
                  (newItem) =>
                    !existing.find((item) => item.__ref === newItem.__ref)
                );

                return [...existing, ...uniqueIncoming];
              },
            },
          },
        },
        Category: {
          fields: {
            comments: {
              // https://www.apollographql.com/docs/react/pagination/key-args/
              keyArgs: false,
              merge(existing = [], incoming: any[]) {
                const uniqueIncoming = incoming.filter(
                  (newItem) =>
                    !existing.find((item) => item.__ref === newItem.__ref)
                );

                return [...existing, ...uniqueIncoming];
              },
            },
          },
        },
        Query: {
          fields: {
            allNews: {
              keyArgs: ["type", "categoryName", "languageCodes"],
              merge(existing = [], incoming: any[]) {
                const uniqueIncoming = incoming.filter(
                  (newItem) =>
                    !existing.find((item) => item.__ref === newItem.__ref)
                );

                return [...existing, ...uniqueIncoming];
              },
            },
            allVideos: {
              keyArgs: ["languageCodes"],
              merge(existing = [], incoming: any[]) {
                const uniqueIncoming = incoming.filter(
                  (newItem) =>
                    !existing.find((item) => item.__ref === newItem.__ref)
                );

                return [...existing, ...uniqueIncoming];
              },
            },
            allCategories: {
              keyArgs: (args) => {
                if (args.discover) {
                  return ["discover", "languageCode"];
                } else {
                  return Object.keys(args);
                }
              },
              merge(existing = [], incoming: any[]) {
                const uniqueIncoming = incoming.filter(
                  (newItem) =>
                    !existing.find((item) => item.__ref === newItem.__ref)
                );

                return [...existing, ...uniqueIncoming];
              },
            },
            allShorts: {
              keyArgs: [],
              merge(existing = [], incoming: any[]) {
                const uniqueIncoming = incoming.filter(
                  (newItem) =>
                    !existing.find((item) => item.__ref === newItem.__ref)
                );

                return [...existing, ...uniqueIncoming];
              },
            },
          },
        },
      },
    }),
  });
}

export function initializeApollo(initialState: any = null) {
  const _apolloClient = apolloClient ?? getApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState: any) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
